import React from "react";
import Roles from "../Config/Roles";
import {
  Person,
  Group,
  LocalAtm,
  // LeaderboardRounded,
  // GridViewSharp,
  // AccountBalanceWalletTwoTone,
  TimelineSharp,
  Payment,
  // TuneSharp,
  WebAsset,
  CorporateFare,
  Business,
  Attribution,
  CardMembership,
} from "@mui/icons-material";
export interface ChilNavigationItem {
  title: string;
  to: string;
  role: number[];
  icon?: JSX.Element;
}
export interface NavigationItem {
  icon: JSX.Element;
  title: string;
  id: string;
  role: number[];
  to?: string;
  items?: ChilNavigationItem[];
}

export interface NavigationItems {
  sidebar: NavigationItem[];
  footer: NavigationItem[];
}

export const navigationItems: NavigationItems = {
  sidebar: [
    // {
    //   icon: <GridViewSharp fontSize="medium" />, //<img src={settingIcon} alt="" />,
    //   title: "Dashboard",
    //   id: "dashboard",
    //   role: [Roles.Admin, Roles.Manager],
    //   items: [
    //     {
    //       title: "Dashboard",
    //       to: "/dashboard",
    //       role: [Roles.SuperAdmin, Roles.Admin, Roles.Manager],
    //       icon: <LeaderboardRounded color="primary" fontSize="large" />,
    //     },
    //   ],
    // },
    {
      icon: <Person fontSize="medium" />,
      title: "Accounts",
      id: "Accounts",
      role: [Roles.Admin, Roles.Manager],
      items: [
        {
          title: "My Profile",
          to: "/profile",
          role: [Roles.Admin],
          icon: <Group color="primary" fontSize="large" />,
        },
        {
          title: "My Account",
          to: "/UserAcount",
          role: [Roles.Admin, Roles.Manager],
          icon: <Person color="primary" fontSize="large" />,
        },
        {
          title: "Payment",
          to: "/Accounts/Payment",
          role: [Roles.Admin],
          icon: <LocalAtm color="primary" fontSize="large" />,
        },
      ],
    },
    {
      icon: <WebAsset fontSize="medium" />,
      title: "Products",
      id: "products",
      role: [Roles.Admin, Roles.Manager],
      items: [
        {
          title: "Products List",
          to: "/Products",
          role: [Roles.Admin, Roles.Manager],
          icon: <WebAsset color="primary" fontSize="large" />,
        },
      ],
    },
    {
      icon: <TimelineSharp fontSize="medium" />,
      title: "Billing History",
      id: "billingHistory",
      role: [Roles.Admin, Roles.Manager],
      items: [
        // {
        //   title: "Monthly Statement",
        //   to: "/Accounts/Monthly",
        //   role: [Roles.Admin, Roles.Manager],
        //   icon: <AccountBalanceWalletTwoTone color="primary" fontSize="large" />,
        // },
        {
          title: "Transaction Details",
          to: "/Accounts/Billing",
          role: [Roles.Admin, Roles.Manager],
          icon: <Payment color="primary" fontSize="large" />,
        },
      ],
    },
    {
      icon: <WebAsset fontSize="medium" />,
      title: "Products",
      id: "products",
      role: [Roles.SuperAdmin],
      items: [
        {
          title: "Products",
          to: "/Admin/Products",
          role: [Roles.SuperAdmin],
          icon: <WebAsset color="primary" fontSize="large" />,
        },
        {
          title: "Attributes",
          to: "/Admin/Attributes",
          role: [Roles.SuperAdmin],
          icon: <Attribution color="primary" fontSize="large" />,
        },
        {
          title: "Subscriptions",
          to: "/Admin/Subscriptions",
          role: [Roles.SuperAdmin],
          icon: <CardMembership color="primary" fontSize="large" />,
        },
      ],
    },
    {
      icon: <Business fontSize="medium" />,
      title: "Organizations",
      id: "organizations",
      role: [Roles.SuperAdmin],
      items: [
        {
          title: "Organization List",
          to: "/Admin/Organizations",
          role: [Roles.SuperAdmin],
          icon: <CorporateFare color="primary" fontSize="large" />,
        },
        {
          title: "Users",
          to: "/Admin/Organizations/Users",
          role: [Roles.SuperAdmin],
          icon: <Group color="primary" fontSize="large" />,
        },
      ],
    },
    // {
    //   icon: <HistoryIcon fontSize="medium" />,//<img alt="" src={historyIcon} />,
    //   title: "Logs",
    //   id: "logs",
    //   role: [Roles.Admin, Roles.Manager],
    //   items: [
    //     {
    //       title: "API Logs",
    //       to: "/Logs/Apilog",
    //       role: [Roles.Admin, Roles.Manager],
    //       icon: <HistoryIcon color="primary" fontSize="large" />,
    //     },
    //   ],
    // },
    // {
    //   icon: <img alt="" src={reportIcon} />,
    //   title: "Reports",
    //   id: 'Reports',
    //   items: [
    //     {
    //       title: "History",
    //       to: "#",
    //     },
    //   ],
    // },
  ],
  footer: [
    // {
    //   icon: <img alt="" src={settingIcon} />,
    //   title: "Settings",
    // },
    // {
    //   icon: <img alt="" src={logoutIcon} />,
    //   title: "Logout",
    // },
  ],
};
export default navigationItems;
