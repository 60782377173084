import React, { useEffect, useState } from "react";
import { 
  Button,  
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { updateProfileApi } from "../Redux/features/userData";
import {
  addressRegex,
  cityNmRegex,
  emailRegex,
  firstLetterUpperCase,
  nameRegex,
  showToastMessage,
  stateNameRegex,
  zipCodeRegex,
} from "../Utils/Utils";
import {
  Country,
  MasterData,
  State,
  UpdateProfileProps,
  UserInfo,
  UserProfileData,
} from "../Models/UserProfile";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-phone-input-2/lib/style.css";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { useLoader } from "../Context/LoaderContext";

const UpdateProfile: React.FC<UpdateProfileProps> = (props) => {
  const userInfo: UserInfo = useAppSelector((state) => state.reducer.userData);
  const masterData: MasterData = useAppSelector((state) => state.masterData);
  const dispatch = useAppDispatch();
  const [phoneErr, setPhoneErr] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [countryDetails, setCountryDetails] = useState<CountryData>({
    name: "",
    dialCode: "",
    countryCode: "",
    format: "",
  });
  const [userData, setUserData] = useState<UserProfileData>({
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    country_code: "",
    state: "",
    postal_code: "",
    phone: "",
    email: "",
    error: {
      first_name: "",
      last_name: "",
      address_1: "",
      address_2: "",
      city: "",
      country_code: "",
      state: "",
      postal_code: "",
      email: "",
      phone: "",
    },
  });

  useEffect(() => {
    setUserData({ ...userInfo, error: { ...userData.error } });
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    if (userInfo.phone)
      try {
        const phoneNumber = phoneNumberUtil.parse("+" + userInfo.phone);
        const countryCode = phoneNumber.getCountryCode();
        const country = phoneNumberUtil.getRegionCodeForNumber(phoneNumber);
        // console.log("Country Code:", countryCode);
        // console.log("Country:", country);
        if (country && countryCode)
          setCountryDetails({
            countryCode: country,
            dialCode: countryCode.toString(),
            name: country,
            format: "",
          });
      } catch (error) {
        console.error("Invalid phone number");
      } // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    // console.log("phone", phone, "country", countryDetails);
    if (userData.phone !== "" && countryDetails && countryDetails.countryCode) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(
          userData.phone,
          countryDetails.countryCode
        );
        const isValidNumber = phoneUtil.isValidNumber(parsedNumber);
        setIsValid(isValidNumber);
        if (!isValidNumber) {
          setPhoneErr("Please Insert Valid Phone Number");
        } else {
          setPhoneErr("");
        }
      } catch (error) {
        // console.log("err", error);
        setIsValid(false);
        setPhoneErr("Please Insert Valid Phone Number");
      }
    }
    // eslint-disable-next-line
  }, [userData, countryDetails]);

  //loader
  const { handleLoader } = useLoader();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserData({
      ...userData,
      [name]: name === "first_name" ? firstLetterUpperCase(value) : value,
      error: { ...userData.error, [name]: "" },
    });
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setUserData({
      ...userData,
      country_code: event.target.value,
      state: "",
      error: { ...userData.error, country_code: "", state: "" },
    });
  };

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setUserData({
      ...userData,
      state: event.target.value,
      error: { ...userData.error, state: "" },
    });
  };

  const validateFields = () => {
    let isError = false;
    let errorData = { ...userData.error };
    if (!userData.first_name || nameRegex.test(userData.first_name) === false) {
      errorData.first_name = "Please Enter Valid First Name";
      isError = true;
    }

    if (!userData.last_name || nameRegex.test(userData.last_name) === false) {
      errorData.last_name = "Please Enter Valid Last Name";
      isError = true;
    }

    if (!userData.email || emailRegex.test(userData.email) === false) {
      errorData.email = "Please Enter Valid Email";
      isError = true;
    }

    if (
      !userData.address_1 ||
      addressRegex.test(userData.address_1) === false
    ) {
      errorData.address_1 = "Please Enter Valid Address";
      isError = true;
    }

    if (userData.country_code === "US") {
      if (userData.state.trim() === "") {
        errorData.state = "Please Select State";
        isError = true;
      }
    } else {
      if (!userData.state || stateNameRegex.test(userData.state) === false) {
        errorData.state = "Please Insert Valid State";
        isError = true;
      }
    }

    if (userData.country_code === "") {
      errorData.country_code = "Please Select Country";
      isError = true;
    }

    if (!userData.city || cityNmRegex.test(userData.city) === false) {
      errorData.city = "Please Insert Valid City Name";
      isError = true;
    }

    if (
      !userData.postal_code ||
      zipCodeRegex.test(userData.postal_code) === false
    ) {
      errorData.postal_code =
        "Please Insert Valid Zip Code, Only numeric character allowed.";
      isError = true;
    }
    if (!isError) {
      updateUserFunc();
    } else {
      setUserData({ ...userData, error: errorData });
    }
  };

  const updateUserFunc = async () => {
    handleLoader(true);
    let data = JSON.stringify({
      first_name: userData.first_name,
      last_name: userData.last_name,
      address_1: userData.address_1,
      address_2: userData.address_2,
      city: userData.city,
      country_code: userData.country_code,
      state: userData.state,
      postal_code: userData.postal_code,
      email: userData.email,
      phone: userData.phone,
    });

    try {
      const res = await dispatch(updateProfileApi(data)).unwrap();

      if (res.success && res.code === 200) {
        handleLoader(false);
        showToastMessage(res.message, "success");
        props.handleClose(true);
      } else {
        handleLoader(false);
        showToastMessage(res.message, "error");
      }
    } catch (err: any) {
      handleLoader(false);
      if (err.message) {
        showToastMessage(err.message, "error");
      } else {
        showToastMessage(err.statusText, "error");
      }
    }
  };

  const handlePhoneNumberChange = (value: string, country: CountryData) => {
    setCountryDetails(country);
    setUserData((prevFormData: any) => ({
      ...prevFormData,
      phone: value,
      error: {
        ...prevFormData.error,
        phone: false,
      },
    }));
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={() => props.handleClose(false)}
    >
      <div className={clsx("updtProfileModal", "modalStyle40")}>
        <div>
          <Typography variant="h6" id="modal-title">
            Update Profile
          </Typography>
          <IconButton
            aria-label="Close"
            className="modalcloseButton"
            onClick={() => props.handleClose(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="padding10">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="First Name *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="first_name"
                    size="small"
                    onChange={handleChange}
                    value={userData.first_name}
                    error={userData.error.first_name !== ""}
                    helperText={
                      userData.error.first_name !== ""
                        ? userData.error.first_name
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="Last Name *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="last_name"
                    size="small"
                    onChange={handleChange}
                    value={userData.last_name}
                    error={userData.error.last_name !== ""}
                    helperText={
                      userData.error.last_name !== ""
                        ? userData.error.last_name
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="Email *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="email"
                    disabled
                    size="small"
                    onChange={handleChange}
                    value={userData.email}
                    error={userData.error.email !== ""}
                    helperText={
                      userData.error.email !== "" ? userData.error.email : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <PhoneInput
                    country={"us"}
                    placeholder={"Phone *"}
                    value={userData.phone}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                      autoComplete: "off", // Disable auto-suggestion
                    }}
                    countryCodeEditable={false}
                    enableSearch
                    disableSearchIcon
                    // disabled={isView}
                    isValid={isValid}
                  />
                  <FormHelperText className="redError">
                    {phoneErr}
                  </FormHelperText>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="Address 1 *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="address_1"
                    size="small"
                    onChange={handleChange}
                    value={userData.address_1}
                    error={userData.error.address_1 !== ""}
                    helperText={
                      userData.error.address_1 !== ""
                        ? userData.error.address_1
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="Address 2"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="address_2"
                    size="small"
                    onChange={handleChange}
                    value={userData.address_2}
                    helperText={
                      userData.error.address_2 !== ""
                        ? userData.error.address_2
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <FormControl required fullWidth>
                    <InputLabel shrink id="country-select-label">
                      Country
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Country"
                          notched
                        />
                      }
                      displayEmpty
                      labelId="country-select-label"
                      value={userData.country_code}
                      onChange={handleCountryChange}
                      MenuProps={{ classes: { paper: "selectDropDown" } }}
                    >
                      <MenuItem disabled value="">
                        Select Country
                      </MenuItem>
                      {masterData.countries.map((country: Country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="redError">
                      {userData.error.country_code}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  {userData.country_code === "US" && (
                    <FormControl required fullWidth>
                      <InputLabel shrink id="state-select-label">
                        State
                      </InputLabel>
                      <Select
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="State"
                            notched
                          />
                        }
                        displayEmpty
                        labelId="state-select-label"
                        value={userData.state}
                        label="State *"
                        onChange={handleStateChange}
                        MenuProps={{ classes: { paper: "selectDropDown" } }}
                      >
                        <MenuItem disabled value="">
                          Select State
                        </MenuItem>
                        {masterData.regions.map((state: State) => (
                          <MenuItem key={state.code} value={state.code}>
                            {state.default_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText className="redError">
                        {userData.error.state}
                      </FormHelperText>
                    </FormControl>
                  )}
                  {userData.country_code !== "US" && (
                    <TextField
                      label="State *"
                      className={clsx("txtBorder")}
                      variant="outlined"
                      name="state"
                      size="small"
                      onChange={handleChange}
                      value={userData.state}
                      error={userData.error.state !== ""}
                      helperText={
                        userData.error.state !== "" ? userData.error.state : ""
                      }
                      autoComplete="off"
                    />
                  )}
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="City *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    name="city"
                    size="small"
                    onChange={handleChange}
                    value={userData.city}
                    error={userData.error.city !== ""}
                    helperText={
                      userData.error.city !== "" ? userData.error.city : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="profileInputContainer">
                <div>
                  <TextField
                    label="Zip Code *"
                    className={clsx("txtBorder")}
                    variant="outlined"
                    size="small"
                    name="postal_code"
                    onChange={handleChange}
                    value={userData.postal_code}
                    error={userData.error.postal_code !== ""}
                    helperText={
                      userData.error.postal_code !== ""
                        ? userData.error.postal_code
                        : ""
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="modalBtnAlignment">
            <Button
              variant="contained"
              className={clsx("margin10", "btnFontSize")}
              onClick={() => props.handleClose(false)}
            >
              Close
            </Button>

            <Button
              variant="contained"
              className="btnFontSize"
              onClick={() => validateFields()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
