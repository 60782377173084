import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_COUNTRIES, GET_REGIONS } from "../../Utils/Constants";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";

export const getCountries = createAsyncThunk(
  "masterData/countries",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.get(GET_COUNTRIES);
      return response.data;
    } catch (e: any) {
      // const error = e as AxiosError;
      // return thunkAPI.rejectWithValue({
      //   status: error.response?.status,
      //   statusText: error.response?.statusText,
      //   message: error.response?.data,
      // });
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const getRegions = createAsyncThunk(
  "masterData/regions",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.get(GET_REGIONS);
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

const initialState: any = {
  countries: [],
  regions: [],
};
//current user
const masterData = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(getCountries.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getCountries.pending", action)
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        // console.log("getCountries.fulfilled", action)
        if (action.payload.code === 200 && action.payload.success) {
          state.countries = action.payload.data;
        }
      })
      .addCase(getCountries.rejected, (state, action) => {
        // console.log("getCountries.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(getRegions.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getRegions.pending", action)
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          state.regions = action.payload.data;
        }
        // console.log("getRegions.fulfilled", action)
      })
      .addCase(getRegions.rejected, (state, action) => {
        // console.log("getRegions.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      });
  },
});

// export const { updatemasterData, updateProfileDetails } = masterData.actions;

export default masterData.reducer;
