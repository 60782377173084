import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Logout_User_URL,
  Resend_OTP_URL,
  SIGNUP_URL,
  Send_OTP_URL,
  UPDATE_USER_PROFILE_URL,
  USER_PROFILE_URL,
  Verify_OTP_URL,
} from "../../Utils/Constants";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";

export const logoutUserApi = createAsyncThunk(
  "userData/logout",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.get(Logout_User_URL);
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const sendOTPApi = createAsyncThunk(
  "userData/send/otp",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.post(
        Send_OTP_URL,
        payload
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const verifyOTPApi = createAsyncThunk(
  "userData/verifyOTP",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.post(
        Verify_OTP_URL,
        payload
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const resendOTPApi = createAsyncThunk(
  "userData/resendOTP",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.post(
        Resend_OTP_URL,
        payload
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const registerUserApi = createAsyncThunk(
  "userData/register",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.post(
        SIGNUP_URL,
        payload
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const updateProfileApi = createAsyncThunk(
  "userData/register",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.patch(
        UPDATE_USER_PROFILE_URL,
        payload
      );
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "userData/userProfile",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.get(USER_PROFILE_URL);
      return response.data;
    } catch (e: any) {      
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

type user = {
  ID: string;
  client_id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  address_1: string;
  address_2: string;
  city: string;
  country_code: string;
  state: string;
  postal_code: string;
  user_role_id: number;
  status: string;
  eToken: string;
  ack: string;
  ali: string;
  isUserCalled: boolean;
};

const initialState: user = {
  ID: "",
  client_id: "",
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  address_1: "",
  address_2: "",
  city: "",
  country_code: "US",
  state: "",
  postal_code: "",
  user_role_id: 2,
  status: "",
  eToken: "",
  ack: "",
  ali: "",
  isUserCalled: false
};
//current user
const userData: any = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUserData: (state, { payload }) => {
      // console.log(payload);
      // state.ID = payload.data.ID;
      // state.client_id = payload.data.client_id;
      // state.email = payload.data.email;
      // state.first_name = payload.data.first_name;
      // state.last_name = payload.data.last_name;
      // state.phone = payload.data.phone;
      // state.address_1 = payload.data.address_1;
      // state.address_2 = payload.data.address_2;
      // state.city = payload.data.city;
      // state.country = payload.data.country;
      // state.state = payload.data.state;
      // state.postal_code = payload.data.postal_code;
      // state.user_role_id = payload.data.user_role_id;
      // state.status = payload.data.status;
    },
    updateProfileDetails: (state, action) => {
      //   console.log(action.payload);
      // state[action.payload.name] = action.payload.value;
    },
  },
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(logoutUserApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("logoutUserApi.pending", action)
      })
      .addCase(logoutUserApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
        }
        // console.log("logoutUserApi.fulfilled", action)
      })
      .addCase(logoutUserApi.rejected, (state, action) => {
        // console.log("logoutUserApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(getUserInfo.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getUserInfo.pending", action)
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          let finalData = action.payload.data;
          state.ID = finalData.ID;
          state.client_id = finalData.client_id;
          state.email = finalData.email;
          state.first_name = finalData.first_name;
          state.last_name = finalData.last_name;
          state.phone = finalData.phone;
          state.address_1 = finalData.address_1;
          state.address_2 = finalData.address_2;
          state.city = finalData.city;
          state.country_code = finalData.country_code;
          state.state = finalData.state;
          state.postal_code = finalData.postal_code;
          state.user_role_id = 2;
          state.status = finalData.status;
          state.eToken = finalData.eToken;
          state.ack = finalData.ack;
          state.ali = finalData.ali;
          state.isUserCalled = true;
        }
        // console.log("getUserInfo.fulfilled", action)
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        // console.log("getUserInfo.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      });
  },
});

export const { updateUserData, updateProfileDetails } = userData.actions;

export default userData.reducer;
