export const Logout_User_URL: string = "/user/logout";

export const Send_OTP_URL: string = "/user/send/otp";

export const Verify_OTP_URL: string = "/user/verify/otp";

export const Resend_OTP_URL: string = "/user/resend/otp";

export const SIGNUP_URL: string = "/user/signup";

export const USER_PROFILE_URL: string = "/userinfo";

export const UPDATE_USER_PROFILE_URL: string = "/userinfo/update";

export const GET_COUNTRIES: string = "/user/countries";

export const GET_REGIONS: string = "/user/country_regions";

export const GET_PRODUCT_LIST_URL: string = "/products";

export const GET_PRODUCT_DETAILS_URL: string = "/product";

export const SUBSCRIBE_PRODUCT_PLAN_URL: string = "/product/subscriptions";

export const ADD_PAYMENT_URL: string = "/payment";

export const GET_PAYMENT_LIST_URL: string = "/payments";

export const SET_DEFAULT_PAYMENT_URL: string = "/payment/defualt";

export const UPDATE_PAYMENT_STATUS_URL: string = "/payment/active/disable";

export const ADD_BALANCE_URL: string = "/payment/addbalance";

export const GET_SUBSCRIBED_APP_LIST_URL: string = "/product/subscriptions";

export const GET_PAYMENT_TYPES_URL: string = "/payment/types";

export const GET_TRANSACTION_DETAILS_URL: string = "/payment/transactionFilter";

export const ADD_ORG_USER_URL: string = "/organization/user";

export const UPDATE_ORG_USER_URL: string = "/organization/user/update";

export const UPDATE_ORG_USER_STATUS_URL: string = "/organization/user";

export const GET_ORG_USER_LIST_URL: string = "/organization/users";

// super Admin APis

export const ADD_ATTRIBUTE_URL: string = "/sp/attribute";

export const UPDATE_ATTRIBUTE_URL: string = "/sp/attribute/update";

export const ATTRIBUTE_LIST_FILTER_URL: string = "/sp/attributes/filter";

export const UPDATE_ATTRIBUTE_STATUS_URL: string = "/sp/attribute";

export const ATTRIBUTE_LIST_URL: string = "/sp/attributes";

export const ADMIN_PRODUCT_LIST_URL: string = "/sp/products";

export const ADMIN_ADD_PRODUCT_URL: string = "/sp/product";

export const ADMIN_UPDATE_PRODUCT_URL: string = "/sp/product/update";

export const GET_ADMIN_SUBSCRIPTION_URL: string = "/sp/subscriptions/filter";

export const GET_ADMIN_SUBSCRIPTION_ATTRIBUTE_URL: string = "/sp/subscription_attributes";

export const GET_ADMIN_SUBSCRIPTION_DETAILS_URL: string = "/sp/subscription";

export const ADD_ADMIN_SUBSCRIPTION_URL: string = "/sp/subscription";

export const UPDATE_ADMIN_SUBSCRIPTION_URL: string = "/sp/subscription/update";

export const UPDATE_ADMIN_SUBSCRIPTION_STATUS_URL: string = "/sp/subscription/status";

export const ADMIN_ORG_LIST_FILTER_URL: string = "/sp/organizations/filter";

export const GET_ADMIN_ORG_DETAILS_URL: string = "/sp/organization";

export const UPDATE_ADMIN_ORG_DETAILS_URL: string = "/sp/organization/update";

export const GET_ADMIN_ORG_PAYMENT_LIST_URL: string = "/sp/organization/payment";

export const GET_ADMIN_ORG_USERS_LIST_URL: string = "/sp/organization/users";
