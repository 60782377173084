import { Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Assets/Logo.png";
import swLogo from "../Assets/sw-Logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import clsx from "clsx";
import cqFavIcon from "../Assets/cq-favicon.png";
import swFavIcon from "../Assets/swFavicon.png";
import navigationItems, { NavigationItem } from "../Utils/navigationItems";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { toggleMenu } from "../Redux/features/settingSlice";

const Sidebar = () => {
  const location = useLocation();
  const navigation = useNavigate();

  const userInfo = useAppSelector((state) => state.reducer.userData);
  // console.log("userInfo",userInfo)
  // userInfo.user_role_id ===> 1 =	SuperAdmin,  2 = Admin,  3 = Manager

  const filteredNavigationItems: NavigationItem[] = navigationItems.sidebar
    .filter((item) => {
      return item.role.includes(userInfo.user_role_id);
    })
    .map((item: NavigationItem) => {
      const filteredItems = item.items?.filter((child) => child.role.includes(userInfo.user_role_id));
      return { ...item, items: filteredItems };
    });
  // console.log("filteredNavigationItems",filteredNavigationItems)
  const dispatch = useAppDispatch();

  const toggleSidebar = () => {
    dispatch(toggleMenu({}));
  };

  //   const switchThemeMode = () => {
  //     dispatch(toggleTheme());
  //   };

  const handleClick = (id: string, to: string) => {
    if (id === current) {
      setCurrent("");
    } else {
      setCurrent(id);
    }

    if (to !== undefined) {
      navigation(to);
    }
  };

  useEffect(() => {
    const path = location.pathname;
    // console.log("path",path)
    // if(path === "/ConnectorSetup"){// toggle isMenuExpanded to false if path is store setup for more space
    //   dispatch(toggleMenuOff())
    // }
    // Iterate through navigationItems to find the matching item
    // and set its ID as the current state
    navigationItems.sidebar.forEach((item) => {
      if (item.to === path) {
        setCurrent(item.id);
      } else if (item.items) {
        item.items.forEach((child) => {
          if (child.to === path) {
            setCurrent(item.id);
            setSubListCurrent(child.title);
            // console.log("path",path)
            // if(path === "/ConnectorSetup"){// toggle isMenuExpanded to false if path is store setup for more space
            //   dispatch(toggleMenuOff())
            // }
          }
        });
      }
    });
    // eslint-disable-next-line
  }, [location]);

  const [current, setCurrent] = useState("");
  const [subListCurrent, setSubListCurrent] = useState("");

  const expanded = useAppSelector((state) => state.reducer.setting.isMenuExpanded);

  const changeCurrentSub = (title: string) => {
    setSubListCurrent(title);
  };

  const getLogo = () => {
    if (process.env.REACT_APP_IS_SW === "true") {
      return expanded ? swLogo : swFavIcon;
    } else {
      return expanded ? Logo : cqFavIcon;
    }
  };

  return (
    <div className={`sidebar ${expanded && "expanded"}`}>
      <header className="txtCenterAlign">
        <img src={getLogo()} className={clsx("width60", "marginVertical10")} alt="" />
        <IconButton size="large" edge="start" className="sidebarIcon" aria-label="menu" sx={{ mr: 2 }} onClick={() => toggleSidebar()}>
          {expanded ? <KeyboardArrowLeftIcon className="" /> : <KeyboardArrowRightIcon className="" />}
        </IconButton>
      </header>
      {/* pass expanded or not from below and above open close icon and logo */}
      <div className={clsx("left-menu", "width100")}>
        {expanded &&
          filteredNavigationItems.map((item: NavigationItem) => (
            <div key={item.id} className="left-listing">
              <ListItem button onClick={() => handleClick(item.id, item.to!)} className={current === item.id ? "active" : ""}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                {expanded && <ListItemText primary={item.title} className="" />}
                {expanded && item.hasOwnProperty("items") ? current === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
              </ListItem>
              {/* <Collapse in={current === item.id} timeout="auto" unmountOnExit> */}
              <Collapse in={current === item.id && expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.items?.map((child, key) => (
                    <SingleLevel
                      key={key}
                      item={child}
                      expanded={expanded}
                      changeCurrentSub={changeCurrentSub}
                      className={subListCurrent === child.title ? "activeSubList" : ""}
                    />
                  ))}
                </List>
              </Collapse>
            </div>
          ))}

        {!expanded &&
          filteredNavigationItems.map((item) =>
            item.items?.map((child, key) => (
              <IconsOnly
                key={key}
                item={child}
                // expanded={expanded}
                changeCurrentSub={changeCurrentSub}
                className={subListCurrent === child.title ? "activeIconOnly" : "iconOnlyPadding"}
              />
            ))
          )}
      </div>

      {/* <div className="sidebarBottom">
        {navigationItems.footer.map((item) => (
          //   <MenuItem key={item.title} item={item} />
          <SingleLevel key={item.title} item={item} expanded={expanded} />
        ))}
      </div> */}
    </div>
  );
};

export default Sidebar;

const SingleLevel = (props: any) => {
  const { item, expanded } = props;

  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <ListItem button className={props.className}>
      {/* {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>} */}
      {expanded && (
        <ListItemText
          primary={item.title}
          className=""
          onClick={() => {
            props.changeCurrentSub(item.title);
            onNavigate(item.to);
          }}
        />
      )}
    </ListItem>
  );
};

const IconsOnly = (props: any) => {
  const { item } = props;

  const navigate = useNavigate();

  const onNavigate = (to: string) => {
    navigate(to);
  };

  return (
    <ListItem button className={props.className}>
      {item.icon && (
        <Tooltip title={item.title} placement="left-end">
          <ListItemIcon
            onClick={() => {
              props.changeCurrentSub(item.title);
              onNavigate(item.to);
            }}
          >
            {item.icon}
          </ListItemIcon>
        </Tooltip>
      )}
    </ListItem>
  );
};
