import { InputLabel, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { formatString } from "../../Utils/Utils";

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const error = searchParams.get("error");
  const errorDescription = searchParams.get("error_description");

  return (
    <div>
      <div className="mainDiv error-page-height dflex">
        <div className="error-details-container">
          <div className="dFlexJustifyCenter">
            <DoDisturbOnIcon color="error" />
            <Typography variant="h5" className="marginLeft10">
              Access Denied
            </Typography>
          </div>
          <InputLabel className="marginTop30">
            <span className="fontWeight">Error:</span> <span>{formatString(error)}</span>
          </InputLabel>
          <InputLabel className="marginTop30">
            <span className="fontWeight">Description:</span> <span>{errorDescription}</span>
          </InputLabel>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
