import { createSlice } from "@reduxjs/toolkit";

type setting ={
  isAuthenticated: boolean;
  isMenuExpanded: boolean;
} 

const initialState : setting = {
  isAuthenticated: false,
  isMenuExpanded: true,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    updateAuth: (state, { payload }) => {
      // console.log(payload);
      state.isAuthenticated = payload;
    },
    toggleMenu: (state, action) => {
      state.isMenuExpanded = !state.isMenuExpanded;
    },
    toggleMenuOff: (state, action) => {
      state.isMenuExpanded = false;
    },
  },
});

export const { updateAuth, toggleMenu, toggleMenuOff } = settingSlice.actions;

export default settingSlice.reducer;
