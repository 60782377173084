import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { GET_PAYMENT_TYPES_URL, GET_TRANSACTION_DETAILS_URL } from "../../Utils/Constants";
import { AxiosResponse } from "axios";

export const getPaymentTypesApi = createAsyncThunk("billingHistory/types", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(GET_PAYMENT_TYPES_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const getTransactionDetailsApi = createAsyncThunk("billingHistory/transactionFilter", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(GET_TRANSACTION_DETAILS_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

const initialState = {};

const billingHistorySlice = createSlice({
  name: "billingHistory",
  initialState,
  reducers: {
    // updateBusinessInfo: (state, action) => {
    //   //   console.log(action.payload);
    //   state[action.payload.name] = action.payload.value;
    // },
  },

  extraReducers(builder) {
    // omit posts loading reducers
    builder
      .addCase(getTransactionDetailsApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getTransactionDetailsApi.pending", action)
      })
      .addCase(getTransactionDetailsApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("getTransactionDetailsApi.fulfilled", action.payload);
      })
      .addCase(getTransactionDetailsApi.rejected, (state, action) => {
        // console.log("getTransactionDetailsApi.rejected", action)
      })
      .addCase(getPaymentTypesApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getPaymentTypesApi.pending", action)
      })
      .addCase(getPaymentTypesApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("getPaymentTypesApi.fulfilled", action.payload);
      })
      .addCase(getPaymentTypesApi.rejected, (state, action) => {
        // console.log("getPaymentTypesApi.rejected", action)
      });
  },
});

// export const { updateBusinessInfo } = billingHistorySlice.actions;

export default billingHistorySlice.reducer;
