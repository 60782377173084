import React, { Suspense, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const SuspenseContainer: React.FC<Props> = ({ children, className = "" }) => (
  <Suspense
    fallback={
      <div>
        <div className={className}>Loading...</div>
      </div>
    }
  >
    {children}
  </Suspense>
);

export default SuspenseContainer;
