import { AppBar, Button, Grid, IconButton, InputLabel, Menu, MenuItem, Popover, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import userIcon from "../Assets/Icons/user-ic.png";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";
import { firstLetterUpperCase } from "../Utils/Utils";
import { useAppSelector } from "../Redux/app/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoader } from "../Context/LoaderContext";
import store, { persistor } from "../Redux/app/store";
import BackToAppBtn from "./ReusableComponents/BackToAppBtn";
import { Apps } from "@mui/icons-material";
import { subscribedAppTypes } from "../Models/Account";

const HeaderComp: React.FC = () => {
  
  const subscribedproducts: subscribedAppTypes[] = useAppSelector((state) => state.product.subscribedproducts);

  const { logout, user, isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const { handleLoader } = useLoader();

  const [appsAnchorEl, setAppsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openApps = Boolean(appsAnchorEl);
  const id = openApps ? "simple-popover" : undefined;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (to: string | null) => {
    setAnchorEl(null);
    if (to !== null) {
      navigate(to);
    }
  };

  const handleLogout = async () => {
    handleLoader(true);

    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");

    //resetting store to initial state
    store.dispatch({ type: "RESET" });
    //resetting persistant storage
    persistor
      .purge()
      .then(() => {
        persistor.persist();
      })
      .catch((error) => console.error(error));

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    handleLoader(false);
  };

  const handleAppsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAppsAnchorEl(event.currentTarget);
  };

  const handleAppsClose = () => {
    setAppsAnchorEl(null);
  };

  const handleAppRedirection = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <AppBar position="static" className="AppBar">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <BackToAppBtn />
        </Typography>

        {/* {children} */}

        {subscribedproducts.length > 0 && (
          <IconButton aria-label={id} className="padding8" onClick={handleAppsClick}>
            <Apps color="primary" />
          </IconButton>
        )}
        <Popover
          id={id}
          open={openApps}
          className="appsPopOver"
          anchorEl={appsAnchorEl}
          onClose={handleAppsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container spacing={0} className="list-container">
            {subscribedproducts.map((product) => (
              <Grid item xs={4} key={product.id} className="">
                <div className="app-container" onClick={() => handleAppRedirection(product.ui_url)}>
                  <img src={product.app_logo} alt="" className="width100px" />
                  <InputLabel className="cursorPointer">{product.name}</InputLabel>
                </div>
              </Grid>
            ))}
          </Grid>
        </Popover>

        {/* {userInfo.user_role_id === 2 && (
          <img alt="" src={settingIcon} className="AppBarIcons" onClick={() => navigate("/MinimumBalance")} />
        )} */}

        {isAuthenticated && (
          <div>
            <Button
              id="demo-positioned-button"
              className={clsx("btnFontSize", "fontWeight")}
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={<img alt="" src={userIcon} className="AppBarUserIcon" />}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {firstLetterUpperCase(user?.name || "")}
            </Button>
            <Menu
              className="headerMenu"
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose(null)}
            >
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderComp;
