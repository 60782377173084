import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./responsive.css"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/app/store";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Auth0Provider, User } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any, user?: User) => {
    console.log("user", user);
    let app = localStorage.getItem("app");
    let callbackUrl = localStorage.getItem("callbackUrl");
    if (app && callbackUrl) {
      localStorage.removeItem("app");
      localStorage.removeItem("callbackUrl");
      window.location.replace(callbackUrl);
    } else {
      navigate(appState?.returnTo === "/" ? "/UserAcount" : appState?.returnTo);
    }
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
