import React, { useEffect, useState } from "react";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LoaderContextProvider } from "./Context/LoaderContext";
import darkTheme from "./Theme/darkTheme";
import lightTheme from "./Theme/lightTheme";
import { useAppDispatch, useAppSelector } from "./Redux/app/hooks";
import Sidebar from "./Layouts/Sidebar";
import HeaderComp from "./Components/HeaderComp";
import MainRoutes from "./Routes/MainRoutes";
import FooterComp from "./Components/FooterComp";
import LoaderComponent from "./Components/LoaderComponent";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { getCountries, getRegions } from "./Redux/features/masterDataSlice";
import { useNavigate } from "react-router";
import { getUserInfo } from "./Redux/features/userData";
import { getSubscribedAppListAPi } from "./Redux/features/productSlice";

const App: React.FC = () => {
  const selectedTheme = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(
    (state) => state.reducer.setting.isMenuExpanded
  );

  const accessToken = localStorage.getItem("accessToken");

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isAccessToken, setIsAccessToken] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const getToken = async () => {
        const accessToken = await getAccessTokenSilently();
        // console.log("accessToken", accessToken);
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          setIsAccessToken(true);
          dispatch(getCountries());
          dispatch(getRegions());
          dispatch(getUserInfo());
          dispatch(getSubscribedAppListAPi());
        }
      };
      getToken();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const navigate = useNavigate();

  // Check for query parameters and navigate to ErrorPage
  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error");
  const errorDescription = searchParams.get("error_description");

  useEffect(() => {
    if (error) {
      navigate(`/error?error=${error}&error_description=${errorDescription}`);
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <LoaderContextProvider>
          <CssVarsProvider
            theme={selectedTheme.darkMode ? darkTheme : lightTheme}
          >
            <CssBaseline />
            <div className="appDisplay">
              {isAuthenticated &&
                accessToken !== null &&
                accessToken !== "undefined" && <Sidebar />}

              <div
                className={
                  isAuthenticated &&
                  accessToken !== null &&
                  accessToken !== "undefined"
                    ? expanded
                      ? "rootContainerExpand"
                      : "rootContainer"
                    : clsx("width100", "height100v")
                }
              >
                {isAuthenticated &&
                  accessToken !== null &&
                  accessToken !== "undefined" && <HeaderComp />}

                <MainRoutes isAccessToken={isAccessToken}/>
                {isAuthenticated &&
                  accessToken !== null &&
                  accessToken !== "undefined" && <FooterComp />}
              </div>
            </div>
          </CssVarsProvider>
          <LoaderComponent />
        </LoaderContextProvider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
