import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";
import {
  GET_PRODUCT_DETAILS_URL,
  GET_PRODUCT_LIST_URL,
  GET_SUBSCRIBED_APP_LIST_URL,
  SUBSCRIBE_PRODUCT_PLAN_URL,
} from "../../Utils/Constants";
import { subscribedAppTypes } from "../../Models/Account";

export const getProductListAPi = createAsyncThunk("product/products", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(GET_PRODUCT_LIST_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const getProductDetailsAPi = createAsyncThunk("product/productsDetails", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(`${GET_PRODUCT_DETAILS_URL}/${payload.id}`);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const subscribeProductPlanApi = createAsyncThunk("product/subscriptions", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(`${SUBSCRIBE_PRODUCT_PLAN_URL}/${payload.product_id}`, payload.data);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const getSubscribedAppListAPi = createAsyncThunk("product/user/subscriptions", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(GET_SUBSCRIBED_APP_LIST_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

type productData = {
  subscribedproducts: subscribedAppTypes[];
};

const initialState: productData = {
  subscribedproducts: [],
};
//current user
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(getProductListAPi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getProductListAPi.pending", action)
      })
      .addCase(getProductListAPi.fulfilled, (state, action) => {
        // console.log("getProductListAPi.fulfilled", action)
      })
      .addCase(getProductListAPi.rejected, (state, action) => {
        // console.log("getProductListAPi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(getProductDetailsAPi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getProductDetailsAPi.pending", action)
      })
      .addCase(getProductDetailsAPi.fulfilled, (state, action) => {
        // console.log("getProductDetailsAPi.fulfilled", action)
      })
      .addCase(getProductDetailsAPi.rejected, (state, action) => {
        // console.log("getProductDetailsAPi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(subscribeProductPlanApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("subscribeProductPlanApi.pending", action)
      })
      .addCase(subscribeProductPlanApi.fulfilled, (state, action) => {
        // console.log("subscribeProductPlanApi.fulfilled", action)
      })
      .addCase(subscribeProductPlanApi.rejected, (state, action) => {
        // console.log("subscribeProductPlanApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(getSubscribedAppListAPi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getSubscribedAppListAPi.pending", action)
      })
      .addCase(getSubscribedAppListAPi.fulfilled, (state, action) => {
        // console.log("getSubscribedAppListAPi.fulfilled", action)
        if (action.payload.code === 200 && action.payload.success) {
          let products = action.payload.data.products
          state.subscribedproducts = products ? products : [];
        }
      })
      .addCase(getSubscribedAppListAPi.rejected, (state, action) => {
        // console.log("getSubscribedAppListAPi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      });
  },
});

// export const { updatemasterData, updateProfileDetails } = productSlice.actions;

export default productSlice.reducer;
