import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import SuspenseContainer from "../Components/SuspenseContainer";
import ProtectedRoutes from "./ProtectedRoutes";
import MyProfile from "../Pages/MyProfile";
import ErrorPage from "../Pages/PublicRoutes/ErrorPage";
import { useAppSelector } from "../Redux/app/hooks";
import BlankPage from "../Pages/BlankPage";

const NotFound = lazy(() => import("../Pages/PublicRoutes/NotFound"));
// const Dashboard = lazy(() => import("../Pages/Dashboard"));
const Registration = lazy(() => import("../Pages/PublicRoutes/Registration"));
const ProductList = lazy(() => import("../Pages/Products/ProductList"));
const ProductSubscription = lazy(() => import("../Pages/Products/ProductSubsciption"));
const UsersList = lazy(() => import("../Pages/Users/UsersList"));
const Payment = lazy(() => import("../Pages/Payment/PaymentList"));
const UserAcount = lazy(() => import("../Pages/Account/UserAccount"));
const Transactions = lazy(() => import("../Pages/Account/TransactionDetails"));
const AttributeList = lazy(() => import("../Pages/SuperAdmin/AttributeMgmt/AttributeList"));
const AdminProducts = lazy(() => import("../Pages/SuperAdmin/ProductMgmt/AdminProductList"));
const AddUpdateProduct = lazy(() => import("../Pages/SuperAdmin/ProductMgmt/AddUpdateProduct"));
const SubscriptionList = lazy(() => import("../Pages/SuperAdmin/SubscriptionMgmt/SubscriptionList"));
const AddUpdtSubscription = lazy(() => import("../Pages/SuperAdmin/SubscriptionMgmt/AddUpdtSubscription"));
// const OrganizationList = lazy(() => import("../Pages/SuperAdmin/OrganizationMgmt/OrganizationList"));
// const OrganizationDetails = lazy(() => import("../Pages/SuperAdmin/OrganizationMgmt/OrganizationDetails"));
// const UsersByOrganization = lazy(() => import("../Pages/SuperAdmin/OrganizationMgmt/UsersByOrganization"));

const MainRoutes = React.memo(({ isAccessToken }: { isAccessToken: boolean }) => {
  //   const user = useAppSelector((state) => state.reducer.setting);
  //   const accessToken = localStorage.getItem("accessToken");
  const userInfo = useAppSelector((state) => state.reducer.userData);
  // Check for query parameters and navigate to ErrorPage
  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error");

  if (error) {
    return (
      <Routes>
        {/* Error Page Route */}
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* * Public Routes */}
      <Route path="/register" element={<Registration />} />

      {/* above Route is conditional beacuse if user is already logged in and try to access login or register page */}

      {/* <Route path="/setPassword/*" element={<SetPassword />} /> */}

      {/* manager only -----------------------*/}
      {/* <Route path="/" element={<ProtectedRoutes roles={[Roles.Manager]} />}>
    </Route> */}

      {/* manager & admin --------------------*/}
      {/* <Route path="/" element={<ProtectedRoutes roles={[Roles.Admin, Roles.Manager]} />}> */}
      <Route
        path="/"
        element={
          <SuspenseContainer className="mainDiv">
            <ProtectedRoutes component={BlankPage} />
          </SuspenseContainer>
        }
      />

      {/* below condition and route is added, because of not found route is displayed after login for fraction of second */}
      {!userInfo.isUserCalled && (
        <Route
          path="*"
          element={
            <SuspenseContainer className="mainDiv">
              <ProtectedRoutes component={BlankPage} />
            </SuspenseContainer>
          }
        />
      )}
      {userInfo.isUserCalled && (
        <>
          {/* <Route
            path="/dashboard"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={Dashboard} />
              </SuspenseContainer>
            }
          /> */}
          <Route
            path="/profile"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={MyProfile} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Products"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={ProductList} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/ProductSubscription/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={ProductSubscription} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Accounts/Users"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={UsersList} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Accounts/Payment"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={Payment} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/UserAcount"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={UserAcount} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Accounts/Billing"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={Transactions} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/Attributes"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AttributeList} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/Products"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AdminProducts} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/AddProduct"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AddUpdateProduct} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/UpdateProduct/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AddUpdateProduct} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/Subscriptions"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={SubscriptionList} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/AddSubscriptions"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AddUpdtSubscription} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/UpdateSubscriptions/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AddUpdtSubscription} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/ViewSubscriptions/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={AddUpdtSubscription} />
              </SuspenseContainer>
            }
          />

          {/* <Route
            path="/Admin/Organizations"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={OrganizationList} />
              </SuspenseContainer>
            }
          />         
          
          <Route
            path="/Admin/Organizations/Users"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={UsersByOrganization} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/Organizations/Users/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={UsersByOrganization} />
              </SuspenseContainer>
            }
          />

          <Route
            path="/Admin/OrganizationDetails/:id"
            element={
              <SuspenseContainer className="mainDiv">
                <ProtectedRoutes component={OrganizationDetails} />
              </SuspenseContainer>
            }
          /> */}
        </>
      )}
      {/* <Route path="/Accounts/Account" element={<BusinessInformation />} /> */}
      {/* </Route> */}

      {/* admin only -------------------------*/}
      {/* <Route path="/" element={<ProtectedRoutes roles={[Roles.Admin]} />}></Route> */}

      {/* super-admin only --------------------*/}
      {/* <Route path="/" element={<ProtectedRoutes roles={[Roles.SuperAdmin]} />}> */}
      {/* <Route path="/Accounts/Clients" element={<SuperAdminClients />} /> */}
      {/* </Route> */}

      {/** Not Found route */}
      <Route
        path="*"
        element={
          <SuspenseContainer className="mainDiv">
            <NotFound />
          </SuspenseContainer>
        }
      />
    </Routes>
  );
});

export default MainRoutes;
