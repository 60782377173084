import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";
import {
  ADD_ADMIN_SUBSCRIPTION_URL,
  GET_ADMIN_SUBSCRIPTION_ATTRIBUTE_URL,
  GET_ADMIN_SUBSCRIPTION_DETAILS_URL,
  GET_ADMIN_SUBSCRIPTION_URL,
  UPDATE_ADMIN_SUBSCRIPTION_STATUS_URL,
  UPDATE_ADMIN_SUBSCRIPTION_URL,
} from "../../Utils/Constants";

//with pagination and filter
export const getAdminSubscriptionFilterListApi = createAsyncThunk("admin_Subscription/filter", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(GET_ADMIN_SUBSCRIPTION_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

// if id is blank then return all attributes
// if id is available then we will get only attributes which is not added in current subscription- update purpose
export const getAdminSubscriptionAttributeListApi = createAsyncThunk(
  "admin_Subscription/attribute/list",
  async (payload: any, thunkAPI) => {
    try {
      const response: AxiosResponse = await AxiosInstance.get(GET_ADMIN_SUBSCRIPTION_ATTRIBUTE_URL, {
        params: {
          ...(payload.id ? { id: payload.id } : {}),
        },
      });
      return response.data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        status: e?.status,
        statusText: e?.statusText,
        message: e.data?.Data?.message,
      });
    }
  }
);

// subscription details for particular subscription id
export const getAdminSubscriptionDetailsApi = createAsyncThunk("admin_Subscription/details", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(`${GET_ADMIN_SUBSCRIPTION_DETAILS_URL}/${payload.id}`);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const addAdminSubscriptionApi = createAsyncThunk("admin_Subscription/add", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ADD_ADMIN_SUBSCRIPTION_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const updateSubscriptionStatusApi = createAsyncThunk("admin_Subscription/status", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(
      `${UPDATE_ADMIN_SUBSCRIPTION_STATUS_URL}/${payload.id}?status=${payload.status}`
    );
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const updateAdminSubscriptionApi = createAsyncThunk("admin_Subscription/update", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(`${UPDATE_ADMIN_SUBSCRIPTION_URL}/${payload.id}`, payload.data);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

const initialState = {};

const adminSubscriptionSlice = createSlice({
  name: "admin_Subscription",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(getAdminSubscriptionFilterListApi.pending, (state, action) => {
        // console.log("getAdminSubscriptionFilterListApi.pending", action)
      })
      .addCase(getAdminSubscriptionFilterListApi.fulfilled, (state, action) => {
        // console.log("getAdminSubscriptionFilterListApi.fulfilled", action)
      })
      .addCase(getAdminSubscriptionFilterListApi.rejected, (state, action) => {
        // console.log("getAdminSubscriptionFilterListApi.rejected", action)
      })

      .addCase(getAdminSubscriptionAttributeListApi.pending, (state, action) => {
        // console.log("getAdminSubscriptionAttributeListApi.pending", action)
      })
      .addCase(getAdminSubscriptionAttributeListApi.fulfilled, (state, action) => {
        // console.log("getAdminSubscriptionAttributeListApi.fulfilled", action)
      })
      .addCase(getAdminSubscriptionAttributeListApi.rejected, (state, action) => {
        // console.log("getAdminSubscriptionAttributeListApi.rejected", action)
      })

      .addCase(getAdminSubscriptionDetailsApi.pending, (state, action) => {
        // console.log("getAdminSubscriptionDetailsApi.pending", action)
      })
      .addCase(getAdminSubscriptionDetailsApi.fulfilled, (state, action) => {
        // console.log("getAdminSubscriptionDetailsApi.fulfilled", action)
      })
      .addCase(getAdminSubscriptionDetailsApi.rejected, (state, action) => {
        // console.log("getAdminSubscriptionDetailsApi.rejected", action)
      })

      .addCase(addAdminSubscriptionApi.pending, (state, action) => {
        // console.log("addAdminSubscriptionApi.pending", action)
      })
      .addCase(addAdminSubscriptionApi.fulfilled, (state, action) => {
        // console.log("addAdminSubscriptionApi.fulfilled", action)
      })
      .addCase(addAdminSubscriptionApi.rejected, (state, action) => {
        // console.log("addAdminSubscriptionApi.rejected", action)
      })

      .addCase(updateAdminSubscriptionApi.pending, (state, action) => {
        // console.log("updateAdminSubscriptionApi.pending", action)
      })
      .addCase(updateAdminSubscriptionApi.fulfilled, (state, action) => {
        // console.log("updateAdminSubscriptionApi.fulfilled", action)
      })
      .addCase(updateAdminSubscriptionApi.rejected, (state, action) => {
        // console.log("updateAdminSubscriptionApi.rejected", action)
      })

      .addCase(updateSubscriptionStatusApi.pending, (state, action) => {
        // console.log("updateSubscriptionStatusApi.pending", action)
      })
      .addCase(updateSubscriptionStatusApi.fulfilled, (state, action) => {
        // console.log("updateSubscriptionStatusApi.fulfilled", action)
      })
      .addCase(updateSubscriptionStatusApi.rejected, (state, action) => {
        // console.log("updateSubscriptionStatusApi.rejected", action)
      });
  },
});

// export const { updatemasterData, updateProfileDetails } = adminSubscriptionSlice.actions;

export default adminSubscriptionSlice.reducer;
