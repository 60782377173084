import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";
import {
  ADD_ATTRIBUTE_URL,
  ATTRIBUTE_LIST_FILTER_URL,
  ATTRIBUTE_LIST_URL,
  UPDATE_ATTRIBUTE_STATUS_URL,
  UPDATE_ATTRIBUTE_URL,
} from "../../Utils/Constants";

export const UpdateAttributeApi = createAsyncThunk("attribute/update", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(`${UPDATE_ATTRIBUTE_URL}/${payload.id}`, payload.data);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const AddAttributeApi = createAsyncThunk("attribute/add", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ADD_ATTRIBUTE_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

//with pagination and filter
export const getAttributeFilterListApi = createAsyncThunk("attribute/filter", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ATTRIBUTE_LIST_FILTER_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const updateAttributeStatusApi = createAsyncThunk("attribute/status", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(`${UPDATE_ATTRIBUTE_STATUS_URL}/${payload.id}?status=${payload.status}`);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

// list without pagination
export const getAttributeListApi = createAsyncThunk("attribute/list", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(ATTRIBUTE_LIST_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

const initialState = {};

const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder
      .addCase(UpdateAttributeApi.pending, (state, action) => {
        // console.log("UpdateAttributeApi.pending", action)
      })
      .addCase(UpdateAttributeApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("UpdateAttributeApi.fulfilled", action.payload);
      })
      .addCase(UpdateAttributeApi.rejected, (state, action) => {
        // console.log("UpdateAttributeApi.rejected", action)
      })
      .addCase(AddAttributeApi.pending, (state, action) => {
        // console.log("AddAttributeApi.pending", action)
      })
      .addCase(AddAttributeApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("AddAttributeApi.fulfilled", action.payload);
      })
      .addCase(AddAttributeApi.rejected, (state, action) => {
        // console.log("AddAttributeApi.rejected", action)
      })
      .addCase(getAttributeFilterListApi.pending, (state, action) => {
        // console.log("getAttributeFilterListApi.pending", action)
      })
      .addCase(getAttributeFilterListApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("getAttributeFilterListApi.fulfilled", action.payload);
      })
      .addCase(getAttributeFilterListApi.rejected, (state, action) => {
        // console.log("getAttributeFilterListApi.rejected", action)
      })

      .addCase(updateAttributeStatusApi.pending, (state, action) => {
        // console.log("updateAttributeStatusApi.pending", action)
      })
      .addCase(updateAttributeStatusApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("updateAttributeStatusApi.fulfilled", action.payload);
      })
      .addCase(updateAttributeStatusApi.rejected, (state, action) => {
        // console.log("updateAttributeStatusApi.rejected", action)
      })
      .addCase(getAttributeListApi.pending, (state, action) => {
        // console.log("getAttributeListApi.pending", action)
      })
      .addCase(getAttributeListApi.fulfilled, (state, action) => {
        if (action.payload.code === 200 && action.payload.success) {
          //   let finalData = action.payload.data;
        }
        // console.log("getAttributeListApi.fulfilled", action.payload);
      })
      .addCase(getAttributeListApi.rejected, (state, action) => {
        // console.log("getAttributeListApi.rejected", action)
      });
  },
});

export default attributeSlice.reducer;
