import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";
import { ADMIN_ADD_PRODUCT_URL, ADMIN_PRODUCT_LIST_URL, ADMIN_UPDATE_PRODUCT_URL } from "../../Utils/Constants";

export const getAdminProductListAPi = createAsyncThunk("admin_product/products", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(ADMIN_PRODUCT_LIST_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const AddAdminProductApi = createAsyncThunk("admin_product/add", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ADMIN_ADD_PRODUCT_URL, payload, {
      headers: {
        "Content-Type": undefined,
        // Set 'Content-Type' to undefined, because in axiosInstance we set "application/json;charset=utf-8" as content type, here we send img in multipart formdata so we need to set undefined and rest will be taken care by axios, it will automatically set multipart
      },
    });
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const updateAdminProductApi = createAsyncThunk("admin_product/update", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(`${ADMIN_UPDATE_PRODUCT_URL}/${payload.id}`, payload.data, {
      headers: {
        "Content-Type": undefined,
        // Set 'Content-Type' to undefined, because in axiosInstance we set "application/json;charset=utf-8" as content type, here we send img in multipart formdata so we need to set undefined and rest will be taken care by axios, it will automatically set multipart
      },
    });
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

const initialState = {};

const adminProductSlice = createSlice({
  name: "admin_product",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(getAdminProductListAPi.pending, (state, action) => {
        // console.log("getAdminProductListAPi.pending", action)
      })
      .addCase(getAdminProductListAPi.fulfilled, (state, action) => {
        // console.log("getAdminProductListAPi.fulfilled", action)
      })
      .addCase(getAdminProductListAPi.rejected, (state, action) => {
        // console.log("getAdminProductListAPi.rejected", action)
      })

      .addCase(AddAdminProductApi.pending, (state, action) => {
        // console.log("AddAdminProductApi.pending", action)
      })
      .addCase(AddAdminProductApi.fulfilled, (state, action) => {
        // console.log("AddAdminProductApi.fulfilled", action)
      })
      .addCase(AddAdminProductApi.rejected, (state, action) => {
        // console.log("AddAdminProductApi.rejected", action)
      })

      .addCase(updateAdminProductApi.pending, (state, action) => {
        // console.log("updateAdminProductApi.pending", action)
      })
      .addCase(updateAdminProductApi.fulfilled, (state, action) => {
        // console.log("updateAdminProductApi.fulfilled", action)
      })
      .addCase(updateAdminProductApi.rejected, (state, action) => {
        // console.log("updateAdminProductApi.rejected", action)
      });
  },
});

// export const { updatemasterData, updateProfileDetails } = adminProductSlice.actions;

export default adminProductSlice.reducer;
