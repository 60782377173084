import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userData from "../features/userData";
import settingSlice from "../features/settingSlice";
import themeSlice from "../features/themeSlice";
import masterDataSlice from "../features/masterDataSlice";
import productSlice from "../features/productSlice";
import PaymentSlice from "../features/PaymentSlice";
import billingHistorySlice from "../features/billingHistorySlice";
import attributeSlice from "../features/attributeSlice";
import adminProductSlice from "../features/adminProductSlice";
import adminSubscriptionSlice from "../features/adminSubscriptionSlice";
// import adminOrgSlice from "../features/adminOrgSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// below combine reducers will be persisted
const rootReducer = combineReducers({
  setting: settingSlice,
  userData: userData,

  //   connector: connectorSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const AllReducers = combineReducers({
  reducer: persistedReducer,
  theme: themeSlice,
  masterData: masterDataSlice,
  product: productSlice,
  Payment: PaymentSlice,
  billingHistory: billingHistorySlice,
  attribute: attributeSlice,
  admin_product: adminProductSlice,
  admin_Subscription: adminSubscriptionSlice,
  // admin_org: adminOrgSlice,
});

const resettableRootReducer = (state: RootState, action: AnyAction) => {
  // console.log("Action",action)
  if (action.type === "RESET") {
    return AllReducers(undefined, action);
  }
  return AllReducers(state, action);
};

const store: any = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
