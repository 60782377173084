import { Button, InputLabel, Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";
import { firstLetterUpperCase, showToastMessage } from "../Utils/Utils";
import { useState } from "react";
import { useEffect } from "react";
import UpdateProfile from "../Components/UpdateProfile";
import { useLoader } from "../Context/LoaderContext";
import { customError } from "../Models/types";
import { UserInfo } from "../Models/UserProfile";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { getUserInfo } from "../Redux/features/userData";

const MyProfile: React.FC = () => {
  const userInfo = useAppSelector((state) => state.reducer.userData);
  const [userData, setUserData] = useState<UserInfo>({
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    country_code: "",
    state: "",
    postal_code: "",
    email: "",
    phone: "",
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { handleLoader } = useLoader();

  const handleOpen = () => {
    setModalVisible(true);
  };
  const handleClose = (apiFlag: boolean) => {
    setModalVisible(false);

    if (apiFlag) {
      fetchData();
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userInfo.isUserCalled) {
      fetchData();
    } else {
      setUserData({
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        address_1: userInfo.address_1,
        address_2: userInfo.address_2,
        city: userInfo.city,
        country_code: userInfo.country_code,
        state: userInfo.state,
        postal_code: userInfo.postal_code,
        email: userInfo.email,
        phone: userInfo.phone,
      });
    }

    return () => {};
    // eslint-disable-next-line
  }, [userInfo.isUserCalled]);

  useEffect(() => {
    let app = localStorage.getItem("app");
    let callbackUrl = localStorage.getItem("callbackUrl");
    if (app && callbackUrl) {
      window.location.replace(callbackUrl);
      localStorage.removeItem("app");
      localStorage.removeItem("callbackUrl");
    }
    return () => {};
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    handleLoader(true);
    try {
      const res = await dispatch(getUserInfo()).unwrap();
      // console.log("res", res);
      if (res.success && res.code === 200) {
        setUserData(res.data);
        handleLoader(false);
      } else {
        handleLoader(false);
        showToastMessage(res.message, "error");
      }
    } catch (error) {
      const err = error as customError;
      // console.error("Failed to Login: ", err);
      handleLoader(false);
      if (err.message) {
        showToastMessage(err.message, "error");
      } else {
        showToastMessage(err.statusText, "error");
      }
    }
  };

  return (
    <div className="">
      <div className="mainDiv">
        <div className="titleContainer">
          <div className="left">
            <div className="pageTitle">
              <Typography variant="h5">User Information</Typography>
            </div>
          </div>
          <div className="right">
            <Button variant="contained" className="btnFontSize" onClick={handleOpen}>
              Edit Profile
            </Button>
          </div>
        </div>

        <div className="">
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              First Name <span>:</span>
            </span>
            {firstLetterUpperCase(userData.first_name)}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Last Name <span>:</span>
            </span>
            {firstLetterUpperCase(userData.last_name)}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Email <span>:</span>
            </span>
            {userData.email}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Phone <span>:</span>
            </span>
            {userData.phone && "+"}
            {userData.phone}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Address 1 <span>:</span>
            </span>
            {userData.address_1}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Address 2 <span>:</span>
            </span>
            {userData.address_2}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              City <span>:</span>
            </span>
            {firstLetterUpperCase(userData.city)}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              State <span>:</span>
            </span>
            {userData.state}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Country <span>:</span>
            </span>
            {userData.country_code}
          </InputLabel>
          <InputLabel className={clsx("lableColor", "padding5")}>
            <span className="infoAlignment">
              Zip Code <span>:</span>
            </span>
            {userData.postal_code}
          </InputLabel>
        </div>
      </div>
      {modalVisible && <UpdateProfile open={modalVisible} handleClose={handleClose} />}
    </div>
  );
};

export default MyProfile;
