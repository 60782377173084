import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosInstance from "../../Network/AxiosInstance";
import { AxiosResponse } from "axios";
import { ADD_BALANCE_URL, ADD_PAYMENT_URL, GET_PAYMENT_LIST_URL, SET_DEFAULT_PAYMENT_URL, UPDATE_PAYMENT_STATUS_URL } from "../../Utils/Constants";

export const addPaymentApi = createAsyncThunk("Payment/add", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ADD_PAYMENT_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const getPaymentListApi = createAsyncThunk("Payment/paymentList", async (_, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.get(GET_PAYMENT_LIST_URL);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const setDefaultPaymentApi = createAsyncThunk("Payment/defualt", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(`${SET_DEFAULT_PAYMENT_URL}/${payload.payment_id}`);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const updatePaymentStatusApi = createAsyncThunk("Payment/active/disable", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.patch(
      `${UPDATE_PAYMENT_STATUS_URL}/${payload.payment_id}?status=${payload.status}`
    );
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

export const addBalanceApi = createAsyncThunk("Payment/addBalance", async (payload: any, thunkAPI) => {
  try {
    const response: AxiosResponse = await AxiosInstance.post(ADD_BALANCE_URL, payload);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue({
      status: e?.status,
      statusText: e?.statusText,
      message: e.data?.Data?.message,
    });
  }
});

const initialState: any = {};
//current user
const PaymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {},
  extraReducers(builder) {
    // omit posts loading reducers
    builder

      .addCase(addPaymentApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("addPaymentApi.pending", action)
      })
      .addCase(addPaymentApi.fulfilled, (state, action) => {
        // console.log("addPaymentApi.fulfilled", action)
      })
      .addCase(addPaymentApi.rejected, (state, action) => {
        // console.log("addPaymentApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(getPaymentListApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("getPaymentListApi.pending", action)
      })
      .addCase(getPaymentListApi.fulfilled, (state, action) => {
        // console.log("getPaymentListApi.fulfilled", action)
      })
      .addCase(getPaymentListApi.rejected, (state, action) => {
        // console.log("getPaymentListApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(setDefaultPaymentApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("setDefaultPaymentApi.pending", action)
      })
      .addCase(setDefaultPaymentApi.fulfilled, (state, action) => {
        // console.log("setDefaultPaymentApi.fulfilled", action)
      })
      .addCase(setDefaultPaymentApi.rejected, (state, action) => {
        // console.log("setDefaultPaymentApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(updatePaymentStatusApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("updatePaymentStatusApi.pending", action)
      })
      .addCase(updatePaymentStatusApi.fulfilled, (state, action) => {
        // console.log("updatePaymentStatusApi.fulfilled", action)
      })
      .addCase(updatePaymentStatusApi.rejected, (state, action) => {
        // console.log("updatePaymentStatusApi.rejected", action)
        // state.status = 'failed'
        // state.error = action.error.message
      })
      .addCase(addBalanceApi.pending, (state, action) => {
        // state.status = 'loading'
        // console.log("addBalanceApi.pending", action)
      })
      .addCase(addBalanceApi.fulfilled, (state, action) => {
        // console.log("addBalanceApi.fulfilled", action)
      })
      .addCase(addBalanceApi.rejected, (state, action) => {
        // console.log("addBalanceApi.rejected", action)
      });
  },
});

// export const { updatemasterData, updateProfileDetails } = PaymentSlice.actions;

export default PaymentSlice.reducer;
