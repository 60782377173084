import { withAuthenticationRequired } from "@auth0/auth0-react";

// import React from "react";
// import { Outlet } from "react-router-dom";
// // import AccessDenied from "../Pages/AccessDenied";
// interface Props {
//   roles: number[];
// }
// const ProtectedRoutes: React.FC<Props> = ({ roles }) => {
//   // const user = useAppSelector((state) => state.reducer.setting);
//   // const accessToken = localStorage.getItem("accessToken");
//   // const userInfo = useAppSelector((state) => state.reducer.userData);

//   // const userHasRequiredRole = roles.includes(userInfo.user_role_id) ? true : false;

//   // if (!user.isAuthenticated || accessToken === null || accessToken === "undefined") {
//   //   // return <Navigate to="/login" />;
//   //   return <Navigate to="/dashboard" />;

//   // }

//   // if (user.isAuthenticated && accessToken !== null && accessToken !== "undefined" && !userHasRequiredRole) {
//   //   return <AccessDenied />;
//   // }

//   return <Outlet />;
// };

// export default ProtectedRoutes;
const ProtectedRoutes = ({ component, ...args }: { [x: string]: any; component: any }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};
export default ProtectedRoutes;
