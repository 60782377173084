import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const BackToAppBtn: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localParams, setLocalParams] = useState<string>("");
  const returnUrl: string | null = searchParams.get("returnUrl");

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("returnUrl")) {
      if (returnUrl) {
        setLocalParams(returnUrl);
      }
      searchParams.delete("returnUrl");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    return () => {};
  }, []);

  return localParams !== "" ? (
    <Button variant="contained" className="btnFontSize" onClick={() => window.location.replace(localParams)}>
      Back To Application
    </Button>
  ) : (
    <></>
  );
};

export default BackToAppBtn;
