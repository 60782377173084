import { Typography } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router";

const FooterComp: React.FC = () => {
  const navigate = useNavigate();
  return (
    <footer className="FooterBar">
      <Typography variant="h6" className={clsx("VersionInfo", "cursorPointer")} onClick={() => navigate("/Version")}>
        Version: 1.0
      </Typography>
      <Typography variant="h6" className="Copyright">
        Copyright © 2023 - {process.env.REACT_APP_IS_SW === "true" ? "SalesWarp" : "Cloudqix"}. All rights reserved
      </Typography>
    </footer>
  );
};

export default FooterComp;
