import CryptoJS from "crypto-js";
import { SnackbarVariantType } from "../Models/types";
import { enqueueSnackbar } from "notistack";

export const numberRegex: RegExp = /^[0-9]+$/;

export const nameRegex: RegExp = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/;

// eslint-disable-next-line
export const emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;

export const pwdRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{12,}$/;

export const cityNmRegex: RegExp = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
export const addressRegex: RegExp = /^[a-zA-Z0-9\s,'-]*$/;
export const zipCodeRegex: RegExp = /^[0-9\s,'-]*$/;
export const stateNameRegex: RegExp = /^[a-zA-Z ]*$/;

export const urlRegex =
  /^(?:https?:\/\/)(?:[^.\s]+\.[^.\s]+(?:\.[^.\s]+)*(?:\/|$))(?:(?:\/|(?:\/[^?\s#]+|{{[^}]+}}|[^{\s/]+))*)(?:\?.*)?(?:#(.*))?$/;

export function firstLetterUpperCase(str: string | undefined) {
  // console.log("str",str)
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string | undefined) {
  if(name){
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name[0]}`,
    };
  }else{
    return {      
      // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: "",
    };
  }
  
}

export function convertAmount(amountString: string) {
  // Parse the input string to a float and round it to 2 decimal places
  const amount = parseFloat(amountString).toFixed(2);

  // Add a dollar sign before the amount
  return `$${amount}`;
}

export function decrypt(encryptedMessage: string, key: string): string {
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedMessage);

  const iv = ciphertext.words.slice(0, 4);
  const encrypted = ciphertext.words.slice(4);

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.lib.WordArray.create(encrypted),
  });

  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    CryptoJS.enc.Utf8.parse(key),
    { iv: CryptoJS.lib.WordArray.create(iv) }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}

//toast message
export const showToastMessage = (message: string, type: SnackbarVariantType) => {
  enqueueSnackbar(message, { variant: type });
};

export function formatString(str: string | null) {
  return str
    ? str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
}

export const urlToImageFile = async (url: string, filename: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const file = new File([data], filename, metadata);
  return file;
};